export const serverErrorMixin = {
  data() {
    return {
      serverErrorMessage: null,
      serverMessage: null,
      hasServerError: false,
      serverErrors: {},
      errorMessages: {
        required: "This field is required",
        invalid: "This field is invalid",
        "invalid-email": "The email is invalid",
        "incorrect_password": "Incorrect password",
      },
      errorFields: {},
    };
  },
  methods: {
    processErrorData(err) {
      this.hasServerError = true;
      this.serverErrorMessage = err.message;
      if (err.errors) {
        this.serverErrors = err.errors;
      }
    },
    clearServerErrors() {
      this.hasServerError = false;
      this.serverErrors = {};
    },
    clearServerError(field) {
      // this.serverErrors[field] = null;
      var arr = field.split(".");
      var obj = this.serverErrors;
      while(arr.length > 1 && (obj = obj[arr.shift()]));
      if (obj && obj[ arr[0] ]) {
        obj[ arr[0] ] = null;
      }

      // console.log('clear err_data ', this.serverErrors);

      // let err_data = field.split('.').reduce( (a, b) => {
      //   return a !== undefined ? a[b] : a;
      // }, this.serverErrors);
      // console.log('clear err_data is ', err_data);

      // if (err_data) {
      //   err_data = null;
      // }

    },
    /* eslint-disable no-unused-vars */
    getServerError(field, _val) {
      // console.log('getFieldError SETUP', field, _val);
      if (this.serverErrors !== null) {
        const err_data = field.split('.').reduce( (a, b) => {
          return a !== undefined ? a[b] : a;
        }, this.serverErrors);

        if (! err_data ) {
          return true;
        }
        const key_msg = err_data[0];
        if (this.errorMessages[key_msg]) {
          return this.errorMessages[key_msg];
        }

        return key_msg;
      }
      return true;
    },
  },
};

export default serverErrorMixin;
