<template>
  <q-form
    ref="loginForm"
    class="login"
    @submit="login"
  >
    <q-card-section>
      <div class="q-pa-sm">
        <h5 class="q-mb-none q-mt-xs text-weight-bold">
          Log In
        </h5>
      </div>
    </q-card-section>

    <q-card-section v-if="getServerError('_') != true" class="q-py-none">
      <div class="q-px-md q-py-sm doc-note doc-note-danger" inset>
        {{ getServerError('_') }}
        </div>
    </q-card-section>

    <q-card-section v-if="!!serverErrorMessage" class="q-py-none">
      <div class="q-px-md q-py-sm doc-note doc-note-danger" inset v-html="serverErrorMessage">
        </div>
    </q-card-section>

    <q-card-section>
      <div class="q-pa-sm q-gutter-y-xs column">
        <q-input
          ref="email"
          v-model="email"
          label="Email"
          dense
          :rules="[val => !!val || 'Field is required', getServerError('email')]"
          @blur="clearServerError('email')"
        />

        <q-input
          v-model="password"
          label="Password"
          dense
          :type="isPwd ? 'password' : 'text'"
          :rules="[val => !!val || 'Field is required']"
        >
          <template v-slot:append>
            <q-icon
              :name="isPwd ? 'visibility_off' : 'visibility'"
              class="cursor-pointer"
              @click="isPwd = !isPwd"
            />
          </template>
        </q-input>

        <q-btn
          type="submit"
          color="primary"
          no-caps
          :loading="loading"
        >
          Log In
        </q-btn>
      </div>
    </q-card-section>

    <q-separator />

    <q-card-section>
      <div class="q-pa-sm q-gutter-y-xs column">
        <q-btn
          v-google-signin-button="gClientId"
          icon="img:statics/img/glogo.svg"
          class="google-signin-button"
          no-caps
        >
          &nbsp; &nbsp; Log In with Google
        </q-btn>
      </div>
    </q-card-section>

    <q-separator />

    <q-card-actions align="between">
      <q-btn
        to="/auth/register"
        color="secondary"
        flat
        no-caps
      >
        Register
      </q-btn>
      <q-btn
        to="/auth/forgot"
        color="secondary"
        flat
        no-caps
      >
        Forgot Password
      </q-btn>
    </q-card-actions>
  </q-form>
</template>

<script>
// import { Vue } from "vue";
// import { mapGetters } from 'vuex';
import { AuthActionTypes } from '../store/action-types';
import { serverErrorMixin } from 'src/mixins/server_error.mixin';
import GoogleSignInButton from 'vue-google-signin-button-directive';
import ApiService from 'src/services/api.service';
import { TokenService } from 'src/services/storage.service';

export default {
  directives: {
    GoogleSignInButton,
  },
  mixins: [serverErrorMixin],
  data() {
    return {
      gClientId: process.env.GOOGLE_IDENTITY_CLIENT_ID,
      email: '',
      password: '',
      isPwd: true,
      loading: false,
      googleLoading: false,
    };
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (vm.$store.getters['auth/isLoggedIn']) {
        next('/');
      }
    });
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters['auth/isLoggedIn'];
    },
  },
  watch: {
    // isLoggedIn: function (new_val) {
    //   if (new_val) {
    //     this.$router.push('/');
    //   }
    // }
  },
  methods: {
    async OnGoogleAuthSuccess(idToken) {
      // Receive the idToken and make your magic with the backend
      console.log('google signin got', idToken);

      this.googleLoading = true;

      try {
        const confData = await ApiService.post('/jwt/auth/google_auth', { token: idToken });
        console.log('got back ', confData);

        if (confData.data) {
          TokenService.saveToken(confData.data.access_token);
          TokenService.saveRefreshToken(confData.data.refresh_token);
          ApiService.setHeader();

          // dispatch
          this.$store.dispatch(`auth/${AuthActionTypes.AuthLoginSuccess}`, confData.data).then(() => {
            this.$router.push({ name: 'accountHome' });
          });
        }
      } catch (err) {
        console.log('cautght err', err);

        if (err.response) {
          console.log('caught err', err.response);
          this.processErrorData(err.response.data);

          this.$refs.confirmForm.validate();
        } else {
          console.log('Error: ', err.message);
          throw err;
        }

        return false;
      } finally {
        this.googleLoading = false;
      }
    },

    OnGoogleAuthFail(error) {
      console.log('google signin error', error);
    },

    login() {
      const { email, password } = this;
      console.log('login called', email, password);
      this.loading = true;
      this.$store
        .dispatch(`auth/${AuthActionTypes.AuthLoginRequest}`, {
          email,
          password,
        })
        .then(() => {
          console.log('in auth then ', this.$route.query);
          this.loading = false;
          if (this.$route.query.nextUrl != null) {
            this.$router.push(this.$route.query.nextUrl);
          } else {
            this.$router.push('/');
          }
        })
        .catch(err => {
          this.loading = false;

          this.processErrorData(err.data);

          this.$refs.loginForm.validate();
        });
    },
  },
};
</script>
